import { Component, Vue, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';

import { firstTimeRightBureau, FirstTimeRight, firstTimeRightTcmg } from '@/support/FirstTimeRight';
import { Report } from '@/models/Report';

@Component<FirstTimeRightDialog>({})
export default class FirstTimeRightDialog extends Vue {
  public $pageTitle = 'FirstTimeRight';

  @Prop()
  protected report!: Report;

  @Prop()
  protected selected!: any;

  protected selectedClone!: any;

  @Prop({ default: false })
  protected isDisabled!: boolean;

  protected isLoading = false;

  protected firstTimeRightReasons: FirstTimeRight[] = [];

  protected firstTimeRightName = '';

  protected selectedFirstTimeRightReasons: string[] = [];

  protected firstTimeRightComment = '';

  protected firstTimeRightValue = true;

  protected mounted() {
    this.parseData();
  }

  protected parseData() {
    this.selectedClone = cloneDeep(this.selected);

    this.firstTimeRightName = this.selectedClone.name;

    if (this.firstTimeRightName === 'IMG') {
      this.firstTimeRightReasons = firstTimeRightTcmg;
      this.firstTimeRightValue = this.selectedClone.stats_first_time_right_tcmg;
      this.firstTimeRightComment = this.selectedClone.stats_first_time_right_tcmg_comment;
      this.selectedFirstTimeRightReasons = this.selectedClone.stats_first_time_right_tcmg_reasons;
    } else {
      this.firstTimeRightReasons = firstTimeRightBureau;
      this.firstTimeRightValue = this.selectedClone.stats_first_time_right_bureau;
      this.firstTimeRightComment = this.selectedClone.stats_first_time_right_bureau_comment;
      this.selectedFirstTimeRightReasons = this.selectedClone.stats_first_time_right_bureau_reasons;
    }
  }

  protected update() {
    const payload: {[key: string]: any} = {};

    if (this.firstTimeRightName === 'IMG') {
      payload.stats_first_time_right_tcmg = this.firstTimeRightValue;
      payload.stats_first_time_right_tcmg_comment = this.firstTimeRightComment;
      payload.stats_first_time_right_tcmg_reasons = this.selectedFirstTimeRightReasons;
    } else {
      payload.stats_first_time_right_bureau = this.firstTimeRightValue;
      payload.stats_first_time_right_bureau_comment = this.firstTimeRightComment;
      payload.stats_first_time_right_bureau_reasons = this.selectedFirstTimeRightReasons;
    }

    this.isLoading = true;

    this.report
      .update(payload)
      .then((report: Report) => {
        this.close();
        this.$emit('firstTimeRightUpdated', report);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected close() {
    this.$emit('input', false);
  }
}
