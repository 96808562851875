var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultDialog" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "dialog-close",
                            on: { click: _vm.close }
                          },
                          on
                        ),
                        [_vm._v("close")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Sluiten")])]
          ),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-end": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                    _c("h2", { staticClass: "dialog-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.firstTimeRightName) +
                          ": First time right "
                      ),
                      !_vm.isDisabled
                        ? _c("span", [_vm._v("aanpassen")])
                        : _vm._e()
                    ])
                  ]),
                  _vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.report && !_vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { sm12: "" } },
                          [
                            _c("span", [
                              _vm._v("Was dit dossier First Time Right?")
                            ]),
                            _c(
                              "v-radio-group",
                              {
                                staticClass:
                                  "approvalChoiceContainer mt-1 mb-2",
                                attrs: {
                                  row: "",
                                  "hide-details": "",
                                  disabled: _vm.isDisabled
                                },
                                model: {
                                  value: _vm.firstTimeRightValue,
                                  callback: function($$v) {
                                    _vm.firstTimeRightValue = $$v
                                  },
                                  expression: "firstTimeRightValue"
                                }
                              },
                              [
                                _c(
                                  "v-radio",
                                  { attrs: { value: true, color: "#a09b1b" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label", value: true },
                                        slot: "label"
                                      },
                                      [_vm._v("ja")]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-radio",
                                  { attrs: { value: false, color: "#a09b1b" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label", value: false },
                                        slot: "label"
                                      },
                                      [_vm._v("nee")]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("v-select", {
                              attrs: {
                                items: _vm.firstTimeRightReasons,
                                label: "Reden",
                                "item-text": "name",
                                "item-value": "value",
                                disabled: _vm.isDisabled,
                                multiple: ""
                              },
                              model: {
                                value: _vm.selectedFirstTimeRightReasons,
                                callback: function($$v) {
                                  _vm.selectedFirstTimeRightReasons = $$v
                                },
                                expression: "selectedFirstTimeRightReasons"
                              }
                            }),
                            _c("v-textarea", {
                              attrs: {
                                name: "comment",
                                label: "Toelichting",
                                disabled: _vm.isDisabled
                              },
                              model: {
                                value: _vm.firstTimeRightComment,
                                callback: function($$v) {
                                  _vm.firstTimeRightComment = $$v
                                },
                                expression: "firstTimeRightComment"
                              }
                            })
                          ],
                          1
                        ),
                        !_vm.isDisabled
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "", "mt-5": "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "justify-end": "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { shrink: "" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fadedText link mr-3",
                                            on: { click: _vm.close }
                                          },
                                          [_vm._v("Annuleren")]
                                        ),
                                        _c(
                                          "MiButton",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.isDisabled ||
                                                !_vm.selectedFirstTimeRightReasons,
                                              color: "primary",
                                              small: "true"
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.update($event)
                                              }
                                            }
                                          },
                                          [_vm._v(" Aanpassen ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }